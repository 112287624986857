import React, { useEffect } from "react"

import Layout from "../components/layout"
import Nav from "../components/navigation"
import SEO from "../components/seo"
import VideoContainer from "../components/videocontainer"

import loopBlack from "../video/looping-black.mp4"

const LoopBlack = () => {
  useEffect(() => {
    document.body.style.backgroundColor = "#111"
  }, [])
  return (
    <Layout className="blackLayout">
      <SEO title="Pintu Logo Animation | Black Loop" />
      <Nav />
      <VideoContainer src={loopBlack} />
    </Layout>
  )
}

export default LoopBlack
